import bigImage1 from '../../assets/stakleniNadstreški/image0.webp'
import bigImage2 from '../../assets/stakleniNadstreški/image1.webp'
import bigImage3 from '../../assets/stakleniNadstreški/image2.webp'
import bigImage4 from '../../assets/stakleniNadstreški/image3.webp'

import smallImage1 from '../../assets/stakleniNadstreški/image0-small.png'
import smallImage2 from '../../assets/stakleniNadstreški/image1-small.png'
import smallImage3 from '../../assets/stakleniNadstreški/image2-small.png'
import smallImage4 from '../../assets/stakleniNadstreški/image3-small.png'

const galleryImagesStakeleniNadstreški = [
  { imgSmall: smallImage1, imgBig: bigImage1, alt: 'stakleniNadstreški1' },
  { imgSmall: smallImage2, imgBig: bigImage2, alt: 'stakleniNadstreški2' },
  { imgSmall: smallImage3, imgBig: bigImage3, alt: 'stakleniNadstreški3' },
  { imgSmall: smallImage4, imgBig: bigImage4, alt: 'stakleniNadstreški4' },
]
export default galleryImagesStakeleniNadstreški
