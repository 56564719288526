import React from 'react'
import classes from './NašaPonudba.module.css'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import image1 from '../../assets/general/ponudbaa.webp'
import image2 from '../../assets/general/ponudbaaaa.webp'
import image3 from '../../assets/general/ponudbaaa.webp'
import image5 from '../../assets/general/tus-kabine-3.webp'
import image4 from '../../assets/general/image3.webp'
import image6 from '../../assets/general/ostalo.webp'

function NašaPonudba() {
  const slideInVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, translateY: 170 },
  }

  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start('visible')
    }
  }, [control, inView])
  // onClick={() => navigate('/stakleneOgraje')}
  // onClick={() => navigate('/predelneStene')}
  // onClick={() => navigate('/steklenaVrata')}
  // onClick={() => navigate('/stekleniNadstreski')}
  // onClick={() => navigate('/tusKabineInSanitarneStene')}
  // onClick={() => navigate('/stakleneOgraje')}
  return (
    <motion.div
      className={classes.ponudbaContainer}
      ref={ref}
      initial='hidden'
      variants={slideInVariant}
      animate={control}
    >
      <h1 className={classes.title}>NAŠA PONUDBA</h1>

      <div className={classes.imagesContainer}>
        <div>
          <div className={classes.imageWraper}>
            <Link to='/stekleneOgraje'>
              <img src={image1} alt='ograje'></img>
            </Link>
          </div>
          <div className={classes.ponudaTitles}>
            <p>STEKLENE OGRAJE</p>
          </div>
        </div>
        <div>
          <div className={classes.imageWraper}>
            <Link to='/predelneStene'>
              <img src={image2} alt='predelneStene'></img>
            </Link>
          </div>
          <div className={classes.ponudaTitles}>
            <p>PREDELNE STENE</p>
          </div>
        </div>
        <div>
          <div className={classes.imageWraper}>
            <Link to='/steklenaVrata'>
              <img src={image3} alt='vrata'></img>
            </Link>
          </div>
          <div className={classes.ponudaTitles}>
            <p>STEKLENA VRATA</p>
          </div>
        </div>
        <div>
          <div className={classes.imageWraper}>
            <Link to='/stekleniNadstreski'>
              <img src={image4} alt='stekleniNadstreski'></img>
            </Link>
          </div>
          <div className={classes.ponudaTitles}>
            <p> STEKLENI NADSTREŠKI</p>
          </div>
        </div>
        <div>
          <div className={classes.imageWraper}>
            <Link to='/tusKabineInSanitarneStene'>
              <img src={image5} alt='tusKabineInSanitarneStene'></img>
            </Link>
          </div>
          <div className={classes.ponudaTitles}>
            <p>TUŠ KABINE IN SANITARNE STENE</p>
          </div>
        </div>
        <div>
          <div className={classes.imageWraper}>
            {/* <Link to='/stakleneOgraje'> */}
            <img src={image6} alt='ostalo'></img>
            {/* </Link> */}
          </div>
          <div className={classes.ponudaTitles}>
            <p>OSTALO</p>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default NašaPonudba
