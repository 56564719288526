import React from 'react'
import { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import classes from './Map.module.css'
function Map() {
  const slideInVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, translateY: 170 },
  }

  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start('visible')
    }
  }, [control, inView])

  return (
    <motion.div
      className={classes.map}
      ref={ref}
      variants={slideInVariant}
      initial='hidden'
      animate={control}
    >
      <div className={classes.titleDiv}>
        <h1 className={classes.title}>LOKACIJA</h1>
      </div>

      <iframe
        width='1950'
        height='442'
        id='gmap_canvas'
        src='https://maps.google.com/maps?q=klanec%2035a,%201218,%20Komenda,%20Slovenia&t=&z=13&ie=UTF8&iwloc=&output=embed'
        frameBorder='0'
        scrolling='no'
        marginHeight='0'
        marginWidth='0'
        className={classes.mapBackground}
      ></iframe>
    </motion.div>
  )
}

export default Map
