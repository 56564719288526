import React from 'react'

import { useEffect, useState, useRef } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import Button from '../UI/Button'
import classes from './ContactForm.module.css'

function ContactForm() {
  const nameSurnameRef = useRef('')
  const emailRef = useRef('')
  const numberRef = useRef('')
  const messageRef = useRef('')
  const [submited, setSubmited] = useState(false)

  const slideInVariant = {
    visible: { opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0 },
  }

  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start('visible')
    }
  }, [control, inView])

  const submitHandler = (e) => {
    e.preventDefault()

    if (
      nameSurnameRef.current.value !== '' &&
      emailRef.current.value !== '' &&
      numberRef.current.value !== '' &&
      messageRef.current.value !== ''
    ) {
      setSubmited(true)
      fetch('https://formsubmit.co/ajax/info@alu-glass.si', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ime: nameSurnameRef.current.value,
          email: emailRef.current.value,
          stevilka: numberRef.current.value,
          povprasevanje: messageRef.current.value,
        }),
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.log(error))
    } else {
      setSubmited(false)
    }
  }

  return (
    <motion.div
      className={classes.formContainer}
      id='kontakt'
      ref={ref}
      variants={slideInVariant}
      initial='hidden'
      animate={control}
    >
      <h1>NAROČILO PONUDBE</h1>
      <form className={classes.form} onSubmit={submitHandler}>
        <div className={classes.formControl}>
          <input
            type='text'
            placeholder='IME IN PRIMEK'
            ref={nameSurnameRef}
            name='ime in primek'
          ></input>
        </div>
        <div className={classes.formControl}>
          <input
            type='email'
            name='email'
            placeholder='EMAIL'
            ref={emailRef}
          ></input>
        </div>
        <div className={classes.formControl}>
          <input
            type='text'
            placeholder='TELEFONSKA ŠTEVILKA'
            ref={numberRef}
            name='stevilka'
          ></input>
        </div>
        <div className={classes.formControl}>
          <textarea
            placeholder='POVPRAŠEVANJE'
            nane='povpraševanje'
            ref={messageRef}
          ></textarea>
        </div>
        {submited && <p>Form Submited Successfuly</p>}
        <Button text='Pošlji' type='submit' />
      </form>
    </motion.div>
  )
}

export default ContactForm
