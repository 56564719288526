import React from 'react'
import classes from './BackgroundImage.module.css'
import { useLocation } from 'react-router-dom'

import videoMain from '../../assets/general/homepage.webm'
import videoMainPhone from '../../assets/general/homepage.mp4'

import imageMain from '../../assets/general/mobileMain.webp'
import { motion } from 'framer-motion'

function BackgroundImage() {
  const location = useLocation()

  let video

  if (
    location.pathname === '/stekleneOgraje' ||
    location.pathname === '/predelneStene' ||
    location.pathname === '/steklenaVrata' ||
    location.pathname === '/stekleniNadstreski' ||
    location.pathname === '/tusKabineInSanitarneStene'
  ) {
    video = (
      <div className={classes.main} style={{ height: '50vh' }}>
        <div
          className={classes.videoWrapper}
          style={{ height: '50vh' }}
          dangerouslySetInnerHTML={{
            __html: `
      <video playsinline loop autoPlay muted autobuffer poster="../../assets/general/mobileMain.webp"
      <source src="${require('../../assets/general/homepage.mp4')}" type="video/mp4" style={{ height: '50vh' }} />
      Your browser does not support the video tag. I suggest you upgrade your browser.
  </video>            
  `,
          }}
        ></div>
      </div>
    )
  } else {
    video = (
      <div className={classes.main}>
        <div
          className={classes.videoWrapper}
          dangerouslySetInnerHTML={{
            __html: `
      <video playsinline loop autoPlay muted autobuffer poster="../../assets/general/mobileMain.webp"
      <source src="${require('../../assets/general/homepage.mp4')}" type="video/mp4" />
      Your browser does not support the video tag. I suggest you upgrade your browser.
  </video>            
  `,
          }}
        ></div>
        <h1>
          MODERNA ARHITEKTURA JE
          <br /> STEKLENA ARHITEKTURA
        </h1>
      </div>
    )
  }

  if (
    location.pathname === '/stekleneOgraje' ||
    location.pathname === '/predelneStene' ||
    location.pathname === '/steklenaVrata' ||
    location.pathname === '/stekleniNadstreski' ||
    location.pathname === '/tusKabineInSanitarneStene'
  ) {
    // video = (
    //   <div className={classes.main} style={{ height: '50vh' }}>
    //     <video src={videoMain} loop muted autoPlay />
    //     <img src={imageMain} alt='backround hero video half'></img>
    //   </div>
    //       <div className={classes.main}>
    //         <div
    //           className={classes.videoWrapper}
    //           dangerouslySetInnerHTML={{
    //             __html: `
    //     <video playsinline loop autoPlay muted autobuffer poster="../../assets/general/mobileMain.webp"
    //     <source src="${require('../../assets/general/homepage.mp4')}" type="video/mp4" />
    //     Your browser does not support the video tag. I suggest you upgrade your browser.
    // </video>
    // `,
    //           }}
    //         ></div>
    //       </div>
    // )
  }

  if (location.pathname === '/uporabno') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }

  if (location.pathname === '/uporabno/steklene-balkonske-ograje') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }
  if (location.pathname === '/uporabno/alu-profil-in-nosilci-za-steklo') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }
  if (location.pathname === '/uporabno/cene-steklenih-ograj') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }

  if (location.pathname === '/uporabno/kaljeno-steklo-za-kamin') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }

  if (location.pathname === '/uporabno/zasteklitev-terase') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }
  if (location.pathname === '/uporabno/graviranje-na-steklo') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }
  if (location.pathname === '/uporabno/kaljeno-steklo-za-kuhinjo') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }
  if (location.pathname === '/uporabno/steklene-stopniščne-ograje') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }

  if (location.pathname === '/uporabno/pivot-notranja-vrata') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }
  if (location.pathname === '/uporabno/steklena-ograja-s-skritim-vpetjem') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }
  if (location.pathname === '/uporabno/steklene-pisarniške-stene') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }



  if (location.pathname === '/uporabno/steklena-streha') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }

  if (location.pathname === '/uporabno/drsna-steklena-vrata') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }

  if (location.pathname === '/uporabno/kaljeno-steklo-po-meri') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }

  if (location.pathname === '/uporabno/walk-in-tus-kabine') {
    video = <div className={classes.main} style={{ display: 'none' }}></div>
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.7 } }}
      exit={{ opacity: 0 }}
    >
      {video}
    </motion.div>
  )
}

export default BackgroundImage
