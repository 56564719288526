import React from 'react'
import classes from './Header.module.css'
import BackgroundImage from './BackgroundImage'
import { Link } from 'react-router-dom'
import logoHeader from '../../assets/general/logoNavbar.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

function Header() {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const navigate = useNavigate()

  const menuHandlerEnter = () => {
    setMenuIsOpen(true)
  }

  const menuHandlerLeave = () => {
    setMenuIsOpen(false)
  }

  const menuHandler = () => {
    setMenuIsOpen((prevValue) => !prevValue)
  }

  const listHandler = () => {
    setMenuIsOpen(false)
  }

  const dropDownMenu = menuIsOpen && (
    <ul
      className={classes.dropdownMenu}
      onMouseEnter={menuHandlerEnter}
      onMouseLeave={menuHandlerLeave}
    >
      <li onClick={listHandler} className={classes.active}>
        <Link to='/stekleneOgraje'>STEKLENE OGRAJE</Link>
      </li>
      <li onClick={listHandler}>
        <Link to='/predelneStene'>PREDELNE STENE</Link>
      </li>
      <li onClick={listHandler}>
        <Link to='/steklenaVrata'>STEKLENA VRATA</Link>
      </li>
      <li onClick={listHandler}>
        <Link to='/tusKabineInSanitarneStene'>
          TUŠ KABINE IN SANITARNE STENE
        </Link>
      </li>
      <li onClick={listHandler}>
        <Link to='/stekleniNadstreski'>STEKLENI NADSTREŠKI</Link>
      </li>
    </ul>
  )

  return (
    <header className={classes.header} id='header'>
      <BackgroundImage />
      <nav className={classes.navbar}>
        <div className={classes.headerIcons}>
          <a
            href='https://www.facebook.com/profile.php?id=100083157070819'
            aria-label='Read more about us on Facebook'
            target='_blank'
          >
            <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
          </a>
          <a
            href='https://www.instagram.com/alu_glass_zasteklitve/?hl=en'
            target='_blank'
            aria-label='Read more about us on Instagram'
          >
            <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
          </a>
        </div>
        <div className={classes.imgWrap}>
          <a href='#header'>
            <img
              src={logoHeader}
              alt='logo-navbar'
              onClick={() => navigate('/')}
            ></img>
          </a>
        </div>

        <ul className={classes.headerMenu}>
          <li onClick={() => navigate('/')}>
            <a href='#header' aria-label='Sends you to header'>
              DOMOV
            </a>
          </li>
          <li onClick={() => navigate('/')}>
            <a href='#oNas' aria-label='Sends you to O Nas'>
              O NAS{' '}
            </a>
          </li>

          <li onMouseEnter={menuHandlerEnter} onMouseLeave={menuHandlerLeave}>
            PONUDBA
          </li>
          {dropDownMenu}
          <li onClick={() => navigate('/')}>
            <a href='#kontakt' aria-label='Sends you to contact'>
              KONTAKT
            </a>
          </li>
          <li onClick={() => navigate('/')}>
            <a href='#uporabno' aria-label='Sends you to uporabno'>
              UPORABNO
            </a>
          </li>
        </ul>

        <div className={classes.buttonContainer}>
          <a
            href='https://www.lestur-okovje.si/'
            aria-label='Check out other website'
            target='_blank'
          >
            <button>KLJUKE IN OKOVJE</button>
          </a>
          <a
            href='https://www.lestur-vrata.si/'
            aria-label='Check out other website about vrata'
            target='_blank'
          >
            <button>LESENA VRATA</button>
          </a>
        </div>

        <div
          className={`${classes.navMenu} ${menuIsOpen ? classes.active : ''}`}
          onClick={menuHandler}
        >
          <span
            className={`${classes.navTop} ${menuIsOpen ? classes.active : ''}`}
          ></span>
          <span
            className={`${classes.navMiddle} ${
              menuIsOpen ? classes.active : ''
            }`}
          ></span>
          <span
            className={`${classes.navBottom} ${
              menuIsOpen ? classes.active : ''
            }`}
          ></span>
        </div>
      </nav>
    </header>
  )
}

export default Header
