import bigImage1 from '../../assets/tusKabine/tus-kabine-1.webp'
import bigImage2 from '../../assets/tusKabine/tus-kabine-2.webp'
import bigImage3 from '../../assets/tusKabine/tus-kabine-3.webp'
import bigImage4 from '../../assets/tusKabine/tus-kabine-4.webp'
import bigImage5 from '../../assets/tusKabine/tus-kabine-5.webp'
import bigImage6 from '../../assets/tusKabine/tus-kabine-6.webp'
import bigImage7 from '../../assets/tusKabine/tus-kabine-7.webp'
import bigImage8 from '../../assets/tusKabine/tus-kabine-8.webp'
import bigImage9 from '../../assets/tusKabine/tus-kabine-9.webp'
import bigImage10 from '../../assets/tusKabine/tus-kabine-10.webp'
import bigImage11 from '../../assets/tusKabine/tus-kabine-11.webp'
import bigImage12 from '../../assets/tusKabine/tus-kabine-12.webp'
import bigImage13 from '../../assets/tusKabine/tus-kabine-13.webp'

import smallImage1 from '../../assets/tusKabine/tus-kabine-small-1.png'
import smallImage2 from '../../assets/tusKabine/tus-kabine-small-2.png'
import smallImage3 from '../../assets/tusKabine/tus-kabine-small-3.png'
import smallImage4 from '../../assets/tusKabine/tus-kabine-small-4.png'
import smallImage5 from '../../assets/tusKabine/tus-kabine-small-5.png'
import smallImage6 from '../../assets/tusKabine/tus-kabine-small-6.png'
import smallImage7 from '../../assets/tusKabine/tus-kabine-small-7.png'
import smallImage8 from '../../assets/tusKabine/tus-kabine-small-8.png'
import smallImage9 from '../../assets/tusKabine/tus-kabine-small-9.png'
import smallImage10 from '../../assets/tusKabine/tus-kabine-small-10.png'
import smallImage11 from '../../assets/tusKabine/tus-kabine-small-11.png'
import smallImage12 from '../../assets/tusKabine/tus-kabine-small-12.png'
import smallImage13 from '../../assets/tusKabine/tus-kabine-small-13.png'

const galleryImagesTusKabine = [
  { imgSmall: smallImage1, imgBig: bigImage1, alt: 'tusKabine1' },
  { imgSmall: smallImage2, imgBig: bigImage2, alt: 'tusKabine2' },
  { imgSmall: smallImage3, imgBig: bigImage3, alt: 'tusKabine3' },
  { imgSmall: smallImage4, imgBig: bigImage4, alt: 'tusKabine4' },
  { imgSmall: smallImage5, imgBig: bigImage5, alt: 'tusKabine5' },
  { imgSmall: smallImage6, imgBig: bigImage6, alt: 'tusKabine6' },
  { imgSmall: smallImage7, imgBig: bigImage7, alt: 'tusKabine7' },
  { imgSmall: smallImage8, imgBig: bigImage8, alt: 'tusKabine8' },
  { imgSmall: smallImage9, imgBig: bigImage9, alt: 'tusKabine9' },
  { imgSmall: smallImage10, imgBig: bigImage10, alt: 'tusKabine10' },
  { imgSmall: smallImage11, imgBig: bigImage11, alt: 'tusKabine11' },
  { imgSmall: smallImage12, imgBig: bigImage12, alt: 'tusKabine12' },
  { imgSmall: smallImage13, imgBig: bigImage13, alt: 'tusKabine13' },
]
export default galleryImagesTusKabine
