import bigImage1 from '../../assets/staklenaVrata/vrata-1.webp'
import bigImage2 from '../../assets/staklenaVrata/vrata-2.webp'
import bigImage3 from '../../assets/staklenaVrata/vrata-3.webp'
import bigImage4 from '../../assets/staklenaVrata/vrata-4.webp'
import bigImage5 from '../../assets/staklenaVrata/vrata-5.webp'
import bigImage6 from '../../assets/staklenaVrata/vrata-6.webp'
import bigImage7 from '../../assets/staklenaVrata/vrata-7.webp'
import bigImage8 from '../../assets/staklenaVrata/vrata-8.webp'
import bigImage9 from '../../assets/staklenaVrata/vrata-9.webp'
import bigImage10 from '../../assets/staklenaVrata/vrata-10.webp'
import bigImage11 from '../../assets/staklenaVrata/vrata-11.webp'
import bigImage12 from '../../assets/staklenaVrata/vrata-12.webp'
import bigImage14 from '../../assets/staklenaVrata/vrata-14.webp'
import bigImage15 from '../../assets/staklenaVrata/vrata-15.webp'
import bigImage16 from '../../assets/staklenaVrata/vrata-16.webp'
import bigImage17 from '../../assets/staklenaVrata/vrata-17.webp'
import bigImage18 from '../../assets/staklenaVrata/vrata-18.webp'
import bigImage20 from '../../assets/staklenaVrata/vrata-20.webp'
import bigImage21 from '../../assets/staklenaVrata/vrata-21.webp'

import bigImage22 from '../../assets/staklenaVrata/staklenaVrata-new (1).webp'
import bigImage23 from '../../assets/staklenaVrata/staklenaVrata-new (2).webp'
import bigImage24 from '../../assets/staklenaVrata/staklenaVrata-new (3).webp'
import bigImage25 from '../../assets/staklenaVrata/staklenaVrata-new (4).webp'
import bigImage26 from '../../assets/staklenaVrata/staklenaVrata-new (5).webp'
import bigImage27 from '../../assets/staklenaVrata/staklenaVrata-new (6).webp'
import bigImage28 from '../../assets/staklenaVrata/staklenaVrata-new (7).webp'

import smallImage1 from '../../assets/staklenaVrata/vrata-small-1.png'
import smallImage2 from '../../assets/staklenaVrata/vrata-small-2.png'
import smallImage3 from '../../assets/staklenaVrata/vrata-small-3.png'
import smallImage4 from '../../assets/staklenaVrata/vrata-small-4.png'
import smallImage5 from '../../assets/staklenaVrata/vrata-small-5.png'
import smallImage6 from '../../assets/staklenaVrata/vrata-small-6.png'
import smallImage7 from '../../assets/staklenaVrata/vrata-small-7.png'
import smallImage8 from '../../assets/staklenaVrata/vrata-small-8.png'
import smallImage9 from '../../assets/staklenaVrata/vrata-small-9.png'
import smallImage10 from '../../assets/staklenaVrata/vrata-small-10.png'
import smallImage11 from '../../assets/staklenaVrata/vrata-small-11.png'
import smallImage12 from '../../assets/staklenaVrata/vrata-small-12.png'
import smallImage14 from '../../assets/staklenaVrata/vrata-small-14.png'
import smallImage15 from '../../assets/staklenaVrata/vrata-small-15.png'
import smallImage16 from '../../assets/staklenaVrata/vrata-small-16.png'
import smallImage17 from '../../assets/staklenaVrata/vrata-small-17.png'
import smallImage18 from '../../assets/staklenaVrata/vrata-small-18.png'
import smallImage20 from '../../assets/staklenaVrata/vrata-small-20.png'
import smallImage21 from '../../assets/staklenaVrata/vrata-small-21.png'

import smallImage22 from '../../assets/staklenaVrata/staklenaVrata-new-small (8).png'
import smallImage23 from '../../assets/staklenaVrata/staklenaVrata-new-small (9).png'
import smallImage24 from '../../assets/staklenaVrata/staklenaVrata-new-small (10).png'
import smallImage25 from '../../assets/staklenaVrata/staklenaVrata-new-small (11).png'
import smallImage26 from '../../assets/staklenaVrata/staklenaVrata-new-small (12).png'
import smallImage27 from '../../assets/staklenaVrata/staklenaVrata-new-small (13).png'
import smallImage28 from '../../assets/staklenaVrata/staklenaVrata-new-small (7).png'

const galleryImagesVrata = [
  { imgSmall: smallImage1, imgBig: bigImage1 ,alt: 'staklenaVrata1'},
  { imgSmall: smallImage2, imgBig: bigImage2 ,alt: 'staklenaVrata2'},
  { imgSmall: smallImage3, imgBig: bigImage3 ,alt: 'staklenaVrata3'},
  { imgSmall: smallImage4, imgBig: bigImage4 ,alt: 'staklenaVrata4'},
  { imgSmall: smallImage5, imgBig: bigImage5 ,alt: 'staklenaVrata5'},
  { imgSmall: smallImage6, imgBig: bigImage6 ,alt: 'staklenaVrata6'},
  { imgSmall: smallImage7, imgBig: bigImage7 ,alt: 'staklenaVrata7'},
  { imgSmall: smallImage8, imgBig: bigImage8 ,alt: 'staklenaVrata8'},
  { imgSmall: smallImage9, imgBig: bigImage9 ,alt: 'staklenaVrata9'},
  { imgSmall: smallImage10, imgBig: bigImage10 ,alt: 'staklenaVrata10'},
  { imgSmall: smallImage11, imgBig: bigImage11 ,alt: 'staklenaVrata11'},
  { imgSmall: smallImage12, imgBig: bigImage12 ,alt: 'staklenaVrata12'},
  { imgSmall: smallImage14, imgBig: bigImage14 ,alt: 'staklenaVrata13'},
  { imgSmall: smallImage15, imgBig: bigImage15 ,alt: 'staklenaVrata14'},
  { imgSmall: smallImage16, imgBig: bigImage16 ,alt: 'staklenaVrata15'},
  { imgSmall: smallImage17, imgBig: bigImage17 ,alt: 'staklenaVrata16'},
  { imgSmall: smallImage18, imgBig: bigImage18 ,alt: 'staklenaVrata17'},
  { imgSmall: smallImage20, imgBig: bigImage20 ,alt: 'staklenaVrata18'},
  { imgSmall: smallImage21, imgBig: bigImage21 ,alt: 'staklenaVrata19'},
  { imgSmall: smallImage22, imgBig: bigImage22 ,alt: 'staklenaVrata20'},
  { imgSmall: smallImage23, imgBig: bigImage23 ,alt: 'staklenaVrata21'},
  { imgSmall: smallImage24, imgBig: bigImage24 ,alt: 'staklenaVrata22'},
  { imgSmall: smallImage25, imgBig: bigImage25 ,alt: 'staklenaVrata23'},
  { imgSmall: smallImage26, imgBig: bigImage26 ,alt: 'staklenaVrata24'},
  { imgSmall: smallImage27, imgBig: bigImage27 ,alt: 'staklenaVrata25'},
  { imgSmall: smallImage28, imgBig: bigImage28 ,alt: 'staklenaVrata26'},
]
export default galleryImagesVrata
