import React from 'react'
import { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

import classes from './Novice.module.css'
import Button from '../UI/Button'



import image9 from '../../assets/blog-photos/novice-main/blog-steklenaStreha.webp'
import image10 from '../../assets/blog-photos/novice-main/blog-drsnaVrata.webp'
import image11 from '../../assets/blog-photos/novice-main/blog-kaljenoSteklo.webp'

function Novice() {
  const slideInVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, translateY: 170 },
  }

  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start('visible')
    }
  }, [control, inView])

  return (
    <motion.div
      className={classes.noviceContainer}
      ref={ref}
      variants={slideInVariant}
      initial='hidden'
      animate={control}
      id='uporabno'
    >
      <h1 className={classes.title}>UPORABNO</h1>
      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <div className={classes.imgWraper}>
            <img src={image9} alt='our new blog-1'></img>
          </div>
          <p className={classes.noviceTitle}>STEKLENA STREHA</p>
          <p className={classes.novicetext}>
          Steklene strehe oz. nadstreški so lep in funkcionalen dodatek k vsaki stavbi, saj zagotavljajo zaščito pred vremenskimi vplivi, hkrati pa omogočajo pretok naravne svetlobe. Postajajo vse bolj priljubljeni v sodobni arhitekturi zaradi...
          </p>
          <Link to={'/uporabno/steklena-streha'}>
            <Button text={'NA VSEBINO'} />
          </Link>
        </div>
        <div className={classes.card}>
          <div className={classes.imgWraper}>
            <img src={image10} alt='our new blog-2'></img>
          </div>
          <p className={classes.noviceTitle}>
          DRSNA STEKLENA VRATA
          </p>
          <p className={classes.novicetext}>
          Drsna vrata so priljubljena izbira za sodobno moderno arhitekturo v stanovanjih in poslovnih objektih, saj zagotavljajo elegantno in funkcionalno rešitev za notranjo uporabo. Ponujajo vrsto prednosti, vključno z večjim....
          </p>
          <Link to={'/uporabno/drsna-steklena-vrata'}>
            <Button text={'NA VSEBINO'} />
          </Link>
        </div>
        <div className={classes.card}>
          <div className={classes.imgWraper}>
            <img src={image11} alt='our new blog-3'></img>
          </div>
          <p className={classes.noviceTitle}>KALJENO STEKLO PO MERI</p>
          <p className={classes.novicetext}>
          Kaljeno steklo je vrsta varnostnega stekla, ki je izdelano s segrevanjem običajnega stekla na visoko temperaturo in nato s hitrim ohlajanjem. Tako nastane močnejši in trdnejši izdelek. Ta postopek, znan tudi kot "kaljenje"...
          </p>
          <Link to={'uporabno/kaljeno-steklo-po-meri'}>
            <Button text={'NA VSEBINO'} />
          </Link>
        </div>
      </div>
      <Link to={'/uporabno'}>
        <Button text={'NA VSE VSEBINE'} className={classes.btnVse} />
      </Link>
    </motion.div>
  )
}

export default Novice
