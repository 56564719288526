import bigImage1 from '../../assets/stakleneOgraje/StakleneOgraje-1.webp'
import bigImage2 from '../../assets/stakleneOgraje/StakleneOgraje-2.webp'
import bigImage3 from '../../assets/stakleneOgraje/StakleneOgraje-3.webp'
import bigImage4 from '../../assets/stakleneOgraje/StakleneOgraje-4.webp'
import bigImage5 from '../../assets/stakleneOgraje/StakleneOgraje-5.webp'
import bigImage6 from '../../assets/stakleneOgraje/StakleneOgraje-6.webp'
import bigImage7 from '../../assets/stakleneOgraje/StakleneOgraje-7.webp'
import bigImage8 from '../../assets/stakleneOgraje/StakleneOgraje-8.webp'
import bigImage9 from '../../assets/stakleneOgraje/StakleneOgraje-9.webp'
import bigImage10 from '../../assets/stakleneOgraje/StakleneOgraje-10.webp'
import bigImage11 from '../../assets/stakleneOgraje/StakleneOgraje-11.webp'
import bigImage12 from '../../assets/stakleneOgraje/StakleneOgraje-12.webp'
import bigImage13 from '../../assets/stakleneOgraje/StakleneOgraje-13.webp'
import bigImage14 from '../../assets/stakleneOgraje/StakleneOgraje-14.webp'
import bigImage15 from '../../assets/stakleneOgraje/StakleneOgraje-15.webp'
import bigImage16 from '../../assets/stakleneOgraje/StakleneOgraje-16.webp'
import bigImage17 from '../../assets/stakleneOgraje/StakleneOgraje-17.webp'
import bigImage18 from '../../assets/stakleneOgraje/StakleneOgraje-18.webp'
import bigImage19 from '../../assets/stakleneOgraje/StakleneOgraje-19.webp'
import bigImage20 from '../../assets/stakleneOgraje/StakleneOgraje-20.webp'
import bigImage21 from '../../assets/stakleneOgraje/StakleneOgraje-21.webp'

import bigImage22 from '../../assets/stakleneOgraje/stakleneOgrajeNew-1 (2).webp'
import bigImage23 from '../../assets/stakleneOgraje/stakleneOgrajeNew-1 (3).webp'
import bigImage24 from '../../assets/stakleneOgraje/stakleneOgrajeNew-1(4).webp'
import bigImage25 from '../../assets/stakleneOgraje/stakleneOgrajeNew-1 (5).webp'
import bigImage26 from '../../assets/stakleneOgraje/stakleneOgrajeNew-1 (6).webp'
import bigImage27 from '../../assets/stakleneOgraje/stakleneOgrajeNew-1 (7).webp'
import bigImage28 from '../../assets/stakleneOgraje/stakleneOgrajeNew-1 (8).webp'
import bigImage29 from '../../assets/stakleneOgraje/stakleneOgrajeNew-1 (9).webp'
import bigImage30 from '../../assets/stakleneOgraje/stakleneOgrajeNew-1 (10).webp'

import smallImage1 from '../../assets/stakleneOgraje/StakleneOgraje-small-1.png'
import smallImage2 from '../../assets/stakleneOgraje/StakleneOgraje-small-2.png'
import smallImage3 from '../../assets/stakleneOgraje/StakleneOgraje-small-3.png'
import smallImage4 from '../../assets/stakleneOgraje/StakleneOgraje-small-4.png'
import smallImage5 from '../../assets/stakleneOgraje/StakleneOgraje-small-5.png'
import smallImage6 from '../../assets/stakleneOgraje/StakleneOgraje-small-6.png'
import smallImage7 from '../../assets/stakleneOgraje/StakleneOgraje-small-7.png'
import smallImage8 from '../../assets/stakleneOgraje/StakleneOgraje-small-8.png'
import smallImage9 from '../../assets/stakleneOgraje/StakleneOgraje-small-9.png'
import smallImage10 from '../../assets/stakleneOgraje/StakleneOgraje-small-10.png'
import smallImage11 from '../../assets/stakleneOgraje/StakleneOgraje-small-11.png'
import smallImage12 from '../../assets/stakleneOgraje/StakleneOgraje-small-12.png'
import smallImage13 from '../../assets/stakleneOgraje/StakleneOgraje-small-13.png'
import smallImage14 from '../../assets/stakleneOgraje/StakleneOgraje-small-14.png'
import smallImage15 from '../../assets/stakleneOgraje/StakleneOgraje-small-15.png'
import smallImage16 from '../../assets/stakleneOgraje/StakleneOgraje-small-16.png'
import smallImage17 from '../../assets/stakleneOgraje/StakleneOgraje-small-17.png'
import smallImage18 from '../../assets/stakleneOgraje/StakleneOgraje-small-18.png'
import smallImage19 from '../../assets/stakleneOgraje/StakleneOgraje-small-19.png'
import smallImage20 from '../../assets/stakleneOgraje/StakleneOgraje-small-20.png'
import smallImage21 from '../../assets/stakleneOgraje/StakleneOgraje-small-21.png'

import smallImage22 from '../../assets/stakleneOgraje/stakleneOgrajeNew-small-1 (2).png'
import smallImage23 from '../../assets/stakleneOgraje/stakleneOgrajeNew-small-1 (3).png'
import smallImage24 from '../../assets/stakleneOgraje/stakleneOgrajeNew-small-1(4).png'
import smallImage25 from '../../assets/stakleneOgraje/stakleneOgrajeNew-small-1 (5).png'
import smallImage26 from '../../assets/stakleneOgraje/stakleneOgrajeNew-small-1 (6).png'
import smallImage27 from '../../assets/stakleneOgraje/stakleneOgrajeNew-small-1 (7).png'
import smallImage28 from '../../assets/stakleneOgraje/stakleneOgrajeNew-small-1 (8).png'
import smallImage29 from '../../assets/stakleneOgraje/stakleneOgrajeNew-small-1 (9).png'
import smallImage30 from '../../assets/stakleneOgraje/stakleneOgrajeNew-small-1 (10).png'

const galleryImagesStakeleneOgraje = [
  { imgSmall: smallImage1, imgBig: bigImage1, alt: 'stakleneOgraje1' },
  { imgSmall: smallImage2, imgBig: bigImage2, alt: 'stakleneOgraje2' },
  { imgSmall: smallImage3, imgBig: bigImage3, alt: 'stakleneOgraje3' },
  { imgSmall: smallImage4, imgBig: bigImage4, alt: 'stakleneOgraje4' },
  { imgSmall: smallImage5, imgBig: bigImage5, alt: 'stakleneOgraje5' },
  { imgSmall: smallImage6, imgBig: bigImage6, alt: 'stakleneOgraje6' },
  { imgSmall: smallImage7, imgBig: bigImage7, alt: 'stakleneOgraje7' },
  { imgSmall: smallImage8, imgBig: bigImage8, alt: 'stakleneOgraje8' },
  { imgSmall: smallImage9, imgBig: bigImage9, alt: 'stakleneOgraje9' },
  { imgSmall: smallImage10, imgBig: bigImage10, alt: 'stakleneOgraje10' },
  { imgSmall: smallImage11, imgBig: bigImage11, alt: 'stakleneOgraje11' },
  { imgSmall: smallImage12, imgBig: bigImage12, alt: 'stakleneOgraje12' },
  { imgSmall: smallImage13, imgBig: bigImage13, alt: 'stakleneOgraje13' },
  { imgSmall: smallImage14, imgBig: bigImage14, alt: 'stakleneOgraje14' },
  { imgSmall: smallImage15, imgBig: bigImage15, alt: 'stakleneOgraje15' },
  { imgSmall: smallImage16, imgBig: bigImage16, alt: 'stakleneOgraje16' },
  { imgSmall: smallImage17, imgBig: bigImage17, alt: 'stakleneOgraje17' },
  { imgSmall: smallImage18, imgBig: bigImage18, alt: 'stakleneOgraje18' },
  { imgSmall: smallImage19, imgBig: bigImage19, alt: 'stakleneOgraje19' },
  { imgSmall: smallImage20, imgBig: bigImage20, alt: 'stakleneOgraje20' },
  { imgSmall: smallImage21, imgBig: bigImage21, alt: 'stakleneOgraje21' },
  { imgSmall: smallImage22, imgBig: bigImage22, alt: 'stakleneOgraje22' },
  { imgSmall: smallImage23, imgBig: bigImage23, alt: 'stakleneOgraje23' },
  { imgSmall: smallImage24, imgBig: bigImage24, alt: 'stakleneOgraje24' },
  { imgSmall: smallImage25, imgBig: bigImage25, alt: 'stakleneOgraje25' },
  { imgSmall: smallImage26, imgBig: bigImage26, alt: 'stakleneOgraje26' },
  { imgSmall: smallImage27, imgBig: bigImage27, alt: 'stakleneOgraje27' },
  { imgSmall: smallImage28, imgBig: bigImage28, alt: 'stakleneOgraje28' },
  { imgSmall: smallImage29, imgBig: bigImage29, alt: 'stakleneOgraje29' },
  { imgSmall: smallImage30, imgBig: bigImage30, alt: 'stakleneOgraje30' },
]
export default galleryImagesStakeleneOgraje
