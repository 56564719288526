import React from 'react'
import classes from './Footer.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import logoFooter from '../../assets/general/logoNavbar.png'

import {
  faPhone,
  faLocationDot,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'

function Footer() {
  return (
    <footer>
      <div className={classes.footerContainer}>
        <div className={classes.imageContainerFooter}>
          <img src={logoFooter} alt='logo-footer'></img>
        </div>
        <div className={classes.groupe}>
          <FontAwesomeIcon
            icon={faPhone}
            className={classes.icon}
          ></FontAwesomeIcon>
          <p className={classes.groupeText}>
            POKLIČITE NAS{' '}
            <span>
              <a href='tel:031-681-658' aria-label='Give us a call'>
                031 461 679
              </a>
            </span>
          </p>
        </div>

        <div className={classes.groupe}>
          <FontAwesomeIcon
            icon={faLocationDot}
            className={classes.icon}
          ></FontAwesomeIcon>
          <p className={classes.groupeText}>
            OBIŠČITE NAS{' '}
            <span>
              <span>Klanec 35a</span>
              <span>1218 Komenda</span>
            </span>
          </p>
        </div>
        <div className={classes.groupe}>
          <FontAwesomeIcon
            icon={faEnvelope}
            className={classes.icon}
          ></FontAwesomeIcon>
          <p className={classes.groupeText}>
            PIŠITE NAM{' '}
            <span>
              <a href='mailto:info@alu-glass.si' aria-label='Send us a mail'>
                info@alu-glass.si
              </a>
            </span>
          </p>
        </div>
      </div>

      <div className={classes.footerContainerBottom}>
        <div className={classes.footerText}>
          <p>Alu glass d.o.o.</p>
          <p>Klanec 35a</p>
          <p>1218 Komenda</p>
        </div>
        <div className={classes.footerText}>
          <div className={classes.textContainer}>
            <p>Davčna številka SI:</p>
            <p>21166412</p>
          </div>
          <div>
            <p className=''>Matična številka:</p>
            <p>9175270000</p>
          </div>
        </div>

        <div className={classes.footerText}>
          <div className={classes.textContainer}>
            <p className=''>Identifikacijska številka za DDV:</p>
            <p>SI21166412</p>
          </div>
          <div>
            <p className=''>Transakcijski račun:</p>
            <p>SI56 2900 0005 3551 714 UniCredit Bank</p>
          </div>
        </div>
        <div className={classes.footerTexts}>
          <p>SLEDITE NAM</p>
          <div className={classes.footerIcons}>
            <a
              href='https://www.facebook.com/profile.php?id=100083157070819'
              target='_blank'
              aria-label='Check us out on facebook'
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className={classes.footerIcon}
              ></FontAwesomeIcon>
            </a>
            <a
              href='https://www.instagram.com/alu_glass_zasteklitve/?hl=en'
              target='_blank'
              aria-label='Check us out on instagram'
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className={classes.footerIcon}
              ></FontAwesomeIcon>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
