import React, { Suspense } from 'react'

import { Routes, Route } from 'react-router-dom'

import Header from './Header'
import ONas from './ONas'
import ContactForm from './ContactForm'
import Novice from './Novice'
import NašaPonudba from './NašaPonudba'
import Map from './Map'

import galleryImagesStakeleneOgraje from '../pages/StakleneOgrajePhotos.js'
import galleryImagesPredelneStene from '../pages/PredelneStenePhotos.js'
import galleryImagesTusKabine from '../pages/TusKabinePhotos.js'
import galleryImagesVrata from '../pages/StaklenaVrataPhotos.js'
import galleryImagesStakeleniNadstreški from '../pages/StakleniNadstreškiPhotos.js'

import LoadingSpinner from '../UI/LoadingSpinner'

import { useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

const StakleneOgrajeLazy = React.lazy(() => import('../pages/StakleneOgraje'))
const PredelneSteneLazy = React.lazy(() => import('../pages/PredelneStene'))
const StaklenaVrataLazy = React.lazy(() => import('../pages/StaklenaVrata'))
const StekleniNadstreškiLazy = React.lazy(() =>
  import('../pages/StekleniNadstreški')
)
const TusKabineInSanitarneSteneLazy = React.lazy(() =>
  import('../pages/TusKabineInSanitarneStene')
)

const BlogsLazy = React.lazy(() => import('../pages/Blogs'))
const StekleneBalkonskeOgraje = React.lazy(() =>
  import('../pages/blogs/StekleneBalkonskeOgraje')
)

const StekleneStene = React.lazy(() => import('../pages/blogs/StekleneStene'))
const CeneStaklenihOgraj = React.lazy(() =>
  import('../pages/blogs/CeneStaklenihOgraj')
)

const KaljenoStekloKamin = React.lazy(() =>
  import('../pages/blogs/KaljenoStekloKamin')
)

const ZasteklitevTerase = React.lazy(() =>
  import('../pages/blogs/ZasteklitevTerase')
)

const StekleneStopniščneOgraje = React.lazy(() =>
  import('../pages/blogs/StekleneStopniščneOgraje')
)

const KaljenoStekloZaKuhinjo = React.lazy(() =>
  import('../pages/blogs/KaljenoStekloZaKuhinjo')
)

const GraviranjeNaSteklo = React.lazy(() =>
  import('../pages/blogs/GraviranjeNaSteklo')
)

const PivotNotranjaVrata = React.lazy(() =>
  import('../pages/blogs/PivotNotranjaVrata')
)

const SteklenaOgrajaSSkritimVpetjem = React.lazy(() =>
  import('../pages/blogs/SteklenaOgrajaSSkritimVpetjem')
)

const SteklenePisarniškeStene = React.lazy(() =>
  import('../pages/blogs/SteklenePisarniškeStene')
)



const KaljenoStekloPoMeri = React.lazy(() =>
  import('../pages/blogs/KaljenoStekloPoMeri')
)
const SteklenaStreha = React.lazy(() =>
  import('../pages/blogs/SteklenaStreha')
)
const WalkInTušKabine = React.lazy(() =>
  import('../pages/blogs/WalkInTušKabine')
)
const DrsnaSteklenaVrata = React.lazy(() =>
  import('../pages/blogs/DrsnaSteklenaVrata')
)

function AnimatedRoutes(props) {
  const location = useLocation()
  return (
    <AnimatePresence>
      <Suspense
        fallback={
          <div
            className='centered'
            style={{
              height: 700,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingSpinner />
          </div>
        }
      >
        <Routes location={location} key={location.pathname}>
          <Route
            path='/'
            element={[
              <Header />,
              <ONas />,
              <NašaPonudba />,
              <Novice />,
              <ContactForm />,
              <Map />,
            ]}
          ></Route>
          <Route path='/contact' element={<ContactForm />}></Route>
          <Route
            path='/stekleneOgraje'
            element={[
              <Header />,

              <StakleneOgrajeLazy
                galleryImages={galleryImagesStakeleneOgraje}
              />,
              <NašaPonudba />,
            ]}
          ></Route>
          <Route
            path='/predelneStene'
            element={[
              <Header />,

              <PredelneSteneLazy galleryImages={galleryImagesPredelneStene} />,
              <NašaPonudba />,
            ]}
          ></Route>
          <Route
            path='/steklenaVrata'
            element={[
              <Header />,

              <StaklenaVrataLazy galleryImages={galleryImagesVrata} />,
              <NašaPonudba />,
            ]}
          ></Route>
          <Route
            path='/stekleniNadstreski'
            element={[
              <Header />,

              <StekleniNadstreškiLazy
                galleryImages={galleryImagesStakeleniNadstreški}
              />,
              <NašaPonudba />,
            ]}
          ></Route>
          <Route
            path='/tusKabineInSanitarneStene'
            element={[
              <Header />,

              <TusKabineInSanitarneSteneLazy
                galleryImages={galleryImagesTusKabine}
              />,
              <NašaPonudba />,
            ]}
          ></Route>

          <Route path='/uporabno/*' element={[<Header />, <BlogsLazy />]}>
            <Route
              path='steklene-balkonske-ograje'
              element={<StekleneBalkonskeOgraje />}
            />
            <Route
              path='kaljeno-steklo-za-kamin'
              element={[<Header />, <KaljenoStekloKamin />]}
            ></Route>
            <Route
              path='alu-profil-in-nosilci-za-steklo'
              element={[<Header />, <StekleneStene />]}
            ></Route>
            <Route
              path='cene-steklenih-ograj'
              element={<CeneStaklenihOgraj />}
            ></Route>
            <Route
              path='zasteklitev-terase'
              element={<ZasteklitevTerase />}
            ></Route>
            <Route
              path='steklene-stopniščne-ograje'
              element={<StekleneStopniščneOgraje />}
            ></Route>
            <Route
              path='kaljeno-steklo-za-kuhinjo'
              element={<KaljenoStekloZaKuhinjo />}
            ></Route>
            <Route
              path='graviranje-na-steklo'
              element={<GraviranjeNaSteklo />}
            ></Route>
            <Route
              path='pivot-notranja-vrata'
              element={<PivotNotranjaVrata />}
            ></Route>

            <Route
              path='steklena-ograja-s-skritim-vpetjem'
              element={<SteklenaOgrajaSSkritimVpetjem />}
            ></Route>
            <Route
              path='steklene-pisarniške-stene'
              element={<SteklenePisarniškeStene />}
            ></Route>

            <Route
              path='kaljeno-steklo-po-meri'
              element={<KaljenoStekloPoMeri />}
            ></Route>
            
            <Route
              path='steklena-streha'
              element={<SteklenaStreha />}
            ></Route>

            <Route
              path='walk-in-tus-kabine'
              element={<WalkInTušKabine />}
            ></Route>

              <Route
              path='drsna-steklena-vrata'
              element={<DrsnaSteklenaVrata />}
            ></Route>
          </Route>
        </Routes>
      </Suspense>
    </AnimatePresence>
  )
}

export default AnimatedRoutes
