import { Fragment } from 'react'
import Footer from './components/layout/Footer'
import AnimatedRoutes from './components/layout/AnimatedRoutes'
import { Helmet } from 'react-helmet'

function App() {
  return (
    <Fragment>
      <AnimatedRoutes />
      <Helmet>
        <meta
          name='title'
          content='Steklarstvo ALU GLASS - Steklene ograje, stene, vrata, nadstreški'
        />
        <meta
          name='description'
          content='Steklarstvo ALU GLASS vam nudi steklene ograje, predelne stene, vrata, vrata in steklene stene za tuš, steklene nadstreške itd. Oglejte si našo ponudbo še danes.'
        />
        <meta
          name='keywords'
          content='Steklene ograje, predelne stene, steklena vrata, tuši'
        />
        <title>ALU GLASS – Steklene ograje, stene, vrata, tuši…</title>
      </Helmet>
      <Footer />
    </Fragment>
  )
}

export default App
