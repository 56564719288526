import bigImage1 from '../../assets/predelneStene/predelne-stene-1.webp'
import bigImage2 from '../../assets/predelneStene/predelne-stene-2.webp'
import bigImage3 from '../../assets/predelneStene/predelne-stene-3.webp'
import bigImage4 from '../../assets/predelneStene/predelne-stene-4.webp'
import bigImage5 from '../../assets/predelneStene/predelne-stene-5.webp'
import bigImage6 from '../../assets/predelneStene/predelne-stene-6.webp'
import bigImage7 from '../../assets/predelneStene/predelne-stene-7.webp'
import bigImage8 from '../../assets/predelneStene/predelne-stene-8.webp'
import bigImage9 from '../../assets/predelneStene/predelne-stene-9.webp'
import bigImage10 from '../../assets/predelneStene/predelne-stene-10.webp'
import bigImage12 from '../../assets/predelneStene/predelne-stene-12.webp'
import bigImage13 from '../../assets/predelneStene/predelne-stene-13.webp'
import bigImage14 from '../../assets/predelneStene/predelne-stene-14.webp'
import bigImage15 from '../../assets/predelneStene/predelne-stene-15.webp'
import bigImage17 from '../../assets/predelneStene/predelne-stene-17.webp'
import bigImage18 from '../../assets/predelneStene/predelne-stene-18.webp'
import bigImage20 from '../../assets/predelneStene/predelne-stene-20.webp'
import bigImage21 from '../../assets/predelneStene/predelne-stene-21.webp'

import bigImage22 from '../../assets/predelneStene/predelneStene-new (2).webp'
import bigImage23 from '../../assets/predelneStene/predelneStene-new (3).webp'
import bigImage24 from '../../assets/predelneStene/predelneStene-new (4).webp'
import bigImage25 from '../../assets/predelneStene/predelneStene-new (5).webp'

import smallImage1 from '../../assets/predelneStene/predelne-stene-small-1.png'
import smallImage2 from '../../assets/predelneStene/predelne-stene-small-2.png'
import smallImage3 from '../../assets/predelneStene/predelne-stene-small-3.png'
import smallImage4 from '../../assets/predelneStene/predelne-stene-small-4.png'
import smallImage5 from '../../assets/predelneStene/predelne-stene-small-5.png'
import smallImage6 from '../../assets/predelneStene/predelne-stene-small-6.png'
import smallImage7 from '../../assets/predelneStene/predelne-stene-small-7.png'
import smallImage8 from '../../assets/predelneStene/predelne-stene-small-8.png'
import smallImage9 from '../../assets/predelneStene/predelne-stene-small-9.png'
import smallImage10 from '../../assets/predelneStene/predelne-stene-small-10.png'
import smallImage12 from '../../assets/predelneStene/predelne-stene-small-12.png'
import smallImage13 from '../../assets/predelneStene/predelne-stene-small-13.png'
import smallImage14 from '../../assets/predelneStene/predelne-stene-small-14.png'
import smallImage15 from '../../assets/predelneStene/predelne-stene-small-15.png'
import smallImage17 from '../../assets/predelneStene/predelne-stene-small-17.jpg'
import smallImage18 from '../../assets/predelneStene/predelne-stene-small-18.jpg'
import smallImage20 from '../../assets/predelneStene/predelne-stene-small-20.jpg'
import smallImage21 from '../../assets/predelneStene/predelne-stene-small-21.jpg'

import smallImage22 from '../../assets/predelneStene/predelneStene-new-small(6).png'
import smallImage23 from '../../assets/predelneStene/predelneStene-new-small(7).png'
import smallImage24 from '../../assets/predelneStene/predelneStene-new-small(8).png'
import smallImage25 from '../../assets/predelneStene/predelneStene-new-small(5).png'

const galleryImagesPredelneStene = [
  { imgSmall: smallImage1, imgBig: bigImage1, alt: 'predelne-stene1' },
  { imgSmall: smallImage2, imgBig: bigImage2, alt: 'predelne-stene2' },
  { imgSmall: smallImage3, imgBig: bigImage3, alt: 'predelne-stene3' },
  { imgSmall: smallImage4, imgBig: bigImage4, alt: 'predelne-stene4' },
  { imgSmall: smallImage5, imgBig: bigImage5, alt: 'predelne-stene5' },
  { imgSmall: smallImage6, imgBig: bigImage6, alt: 'predelne-stene6' },
  { imgSmall: smallImage7, imgBig: bigImage7, alt: 'predelne-stene7' },
  { imgSmall: smallImage8, imgBig: bigImage8, alt: 'predelne-stene8' },
  { imgSmall: smallImage9, imgBig: bigImage9, alt: 'predelne-stene9' },
  { imgSmall: smallImage10, imgBig: bigImage10, alt: 'predelne-stene10' },
  // { imgSmall: smallImage11, imgBig: bigImage11 },
  { imgSmall: smallImage12, imgBig: bigImage12, alt: 'predelne-stene11' },
  { imgSmall: smallImage13, imgBig: bigImage13, alt: 'predelne-stene12' },
  { imgSmall: smallImage14, imgBig: bigImage14, alt: 'predelne-stene13' },
  { imgSmall: smallImage15, imgBig: bigImage15, alt: 'predelne-stene14' },
  // { imgSmall: smallImage16, imgBig: bigImage16 },
  { imgSmall: smallImage17, imgBig: bigImage17, alt: 'predelne-stene15' },
  { imgSmall: smallImage18, imgBig: bigImage18, alt: 'predelne-stene16' },
  { imgSmall: smallImage20, imgBig: bigImage20, alt: 'predelne-stene17' },
  { imgSmall: smallImage21, imgBig: bigImage21, alt: 'predelne-stene18' },
  { imgSmall: smallImage22, imgBig: bigImage22, alt: 'predelne-stene19' },
  { imgSmall: smallImage23, imgBig: bigImage23, alt: 'predelne-stene20' },
  { imgSmall: smallImage24, imgBig: bigImage24, alt: 'predelne-stene21' },
  { imgSmall: smallImage25, imgBig: bigImage25, alt: 'predelne-stene22' },
]
export default galleryImagesPredelneStene
