import React from 'react'
import classes from './ONas.module.css'
import image1 from '../../assets/general/ponudba.webp'
import image2 from '../../assets/general/sastenek.webp'
import image3 from '../../assets/general/izvedba.webp'
import image4 from '../../assets/general/vgaditev.webp'
import { useEffect } from 'react'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

function ONas() {
  const slideInVariant = {
    visible: { opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0 },
  }

  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start('visible')
    }
  }, [control, inView])

  return (
    <motion.div
      className={classes.oNas}
      id='oNas'
      ref={ref}
      variants={slideInVariant}
      initial='hidden'
      animate={control}
    >
      <h1 className={classes.oNasTittle}>O NAS</h1>
      <div className={classes.textContainer}>
        <p>
          V podjetju Alu Glass d.o.o. nudimo izdelavo in vgraditev različnih
          zasteklitev v kombinaciji z drugimi materiali, kot sta les in kovine.
          Sem sodijo steklene ograje, predelne stene, različna vrata (krilna,
          nihajna, drsna, pivot, avtomatska), tuš kabine in nadstreški.
        </p>
        <p>
          Pri opravljanju naših storitev smo natančni, vestni, strokovni in
          točni. Uporabljamo materiale najboljših kakovosti, saj nam je
          zadovoljstvo naših strank glavna prioriteta. Ponosni smo na vse
          uspešno zaključene projekte, ki smo jih dosegli skupaj z našo
          strokovno usposobljeno ekipo.
        </p>
      </div>
      <div className={classes.koraciContainer}>
        <div className={classes.korak}>
          <div className={classes.imgWrap}>
            <img src={image2} alt='ponudba'></img>
          </div>
          <p className={classes.title}>1. PONUDBA</p>
          <p>
            Po prejetem povpraševanju vam sestavimo informativno ponudbo, ki bo
            prilagojena vašim željam in zahtevam. Zaželena je jasna definicija
            želja in okvirnih dimenzij, da bo ponudba čim bolj točna.
          </p>
        </div>
        <div className={classes.korak}>
          <div className={classes.imgWrap}>
            <img src={image1} alt='izmera'></img>
          </div>

          <p className={classes.title}>2. IZMERA</p>
          <p>
            V primeru potrditve ponudbe ali pa nerešenih tehničnih vprašanj se
            dogovorimo za izmero oziroma ogled na objektu, kjer ocenimo dejansko
            stanje in izberemo optimalno rešitev.
          </p>
        </div>
        <div className={classes.korak}>
          <div className={classes.imgWrap}>
            <img src={image3} alt='IZDELAVA'></img>
          </div>

          <p className={classes.title}>3. IZDELAVA</p>
          <p>
            Po potrjeni ponudbi in izmeri sledi priprava in izdelava vseh
            potrebnih elementov za vaš objekt.
          </p>
        </div>
        <div className={classes.korak}>
          <div className={classes.imgWrap}>
            <img src={image4} alt='VGRADITEV'></img>
          </div>

          <p className={classes.title}>4. VGRADITEV</p>
          <p>V zadnji fazi naročene izdelke dobavimo in jih skrbno vgradimo.</p>
        </div>
      </div>
    </motion.div>
  )
}

export default ONas
